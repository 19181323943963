import "owl.carousel";

$(document).ready(function () {
  $("#testimonial-slider").owlCarousel({
    pagination: true,
    autoplay: 1000,
    autoplayTimeout: 10000,
    autoplayHoverPause: true,
    loop: true,
    responsive: {
      0: {
        items: 1,
      },
      480: {
        items: 1,
      },
      568: {
        items: 2,
      },
      667: {
        items: 2,
      },
      768: {
        items: 3,
      },
      1024: {
        items: 3,
      },
    },
  });

  $(".owl-item").on("click", function () {
    $(this).find(".description").toggleClass("truncated");
  });
});
